import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Box, IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SummaryTabHeader from "./SummaryTabHeader";
import SummaryPageContainer from "./SummaryPageContainer";
import SummaryMapContainer from "./SummaryMapContainer";
import TransactionsPageContainer from "./TransactionsPageContainer";
import Loader from "../loader/Loader";
import { AppContext } from "../../context/AppContext";
import { removeAuthInfo } from "../../utils/AuthUtils";
import {
  isTokenError,
  showCatchedExceptionOnToast,
  showServerExceptionOnToast,
} from "../../utils/ExceptionUtils";
import {
  SUMMARY_DEV1T,
  SUMMARY_DEV2T,
  SUMMARY_ACC1T,
  SUMMARY_ACC2T,
  DEFAULT_TIMEZONE_OFFSET,
  DEFAULT_PAGE_SIZE,
} from "../../constants/Constants";
import {
  fetchDeviceSummaryReport,
  fetchDeviceTransactionsSummaryReport,
  fetchAccountSummaryReport,
  fetchAccountTransactionsSummaryReport,
} from "../../services/SummaryService";
import {
  getDateBySubtractMonth,
  getCurrentDateYYYYMMDD,
  formatDateBasedOnLocale,
} from "../../utils/DateUtils";
import { getTypeAndIdFromPathComponent } from "../../utils/CommonUtils";
import { ToastContainer, toast } from "material-react-toastify";
import GenericError from "../GenericError/GenericError";

import AccountImage from "../../assets/images/search-accounts.png";
import DeviceImage from "../../assets/images/search-devices.png";
import ResultsImage from "../../assets/images/search-results.png";
import {
  REPORT_ACC1T,
  REPORT_ACC2T,
  REPORT_DEV1T,
  REPORT_DEV2T,
} from "../../constants/APIConstants";
import ErrorAlert from "../shared/ErrorAlert/ErrorAlert";
import { useTheme } from "@emotion/react";

const noDataReasons = [
  {
    title:
      "We couldn't find any device that match your criteria. Please make some edits and try again.",
    image: DeviceImage,
  },
  {
    title:
      "We couldn't find any account that match your criteria. Please make some edits and try again.",
    image: AccountImage,
  },
  {
    title:
      "We couldn't find any results that match your criteria. Please make some edits and try again.",
    image: ResultsImage,
  },
];

const Overview = (props) => {
  const appContext = useContext(AppContext);
  const { getUserReportByAPI } = appContext;
  const theme = useTheme();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [summaryTransactionsData, setSummaryTransactionsData] = useState(null);
  const [shiftColumn, setShiftColumn] = useState(false);

  const location = useLocation();
  const { type, id } =
    (location && location.state) ||
    getTypeAndIdFromPathComponent(location.pathname);
  useEffect(() => {
    setSelectedTab(0);
    if (type === "device") {
      fetchDeviceSummaryReportData();
    } else if (type === "account") {
      fetchAccountSummaryReportData();
    }
  }, [location]);

  const getErrorByType = () => {
    if (type === "device") {
      return noDataReasons[0];
    } else if (type === "account") {
      return noDataReasons[1];
    } else {
      return noDataReasons[3];
    }
  };

  const onTabSelect = (tabIndex) => {
    setSelectedTab(tabIndex);
    setShiftColumn(false);
  };

  const validateAndShowError = (status) => {
    const tokenError = isTokenError(status);
    if (tokenError) {
      removeAuthInfo();
      appContext.setUserDetails(null);
    } else {
      showServerExceptionOnToast(toast, 1, status);
    }
  };

  const fetchDeviceSummaryReportData = () => {
    try {
      const report = getUserReportByAPI(REPORT_DEV1T);
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : SUMMARY_DEV1T,
        appId: appContext.selectedUserApp.AppId,
        DeviceID: id,
      };
      setShowLoader(true);
      fetchDeviceSummaryReport(payload)
        .then((response) => {
          if (response.Status === 0) {
            setSummaryData(response);
            fetchDeviceTransactionsSummaryReportData();
          } else {
            validateAndShowError(response.Status);
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const fetchDeviceTransactionsSummaryReportData = () => {
    try {
      const report = getUserReportByAPI(REPORT_DEV2T);
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : SUMMARY_DEV2T,
        appId: appContext.selectedUserApp.AppId,
        DeviceID: id,
        startDate: getDateBySubtractMonth(3),
        endDate: getCurrentDateYYYYMMDD(),
        PageNumber: 1,
        PageSize: DEFAULT_PAGE_SIZE,
        timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
      };
      setShowLoader(true);
      fetchDeviceTransactionsSummaryReport(payload)
        .then((response) => {
          if (response.Status === 0) {
            setSummaryTransactionsData(response);
          } else {
            validateAndShowError(response.Status);
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const fetchAccountSummaryReportData = () => {
    try {
      const report = getUserReportByAPI(REPORT_ACC1T);
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : SUMMARY_ACC1T,
        appId: appContext.selectedUserApp.AppId,
        AccountId: id,
      };
      setShowLoader(true);
      fetchAccountSummaryReport(payload)
        .then((response) => {
          if (response.Status === 0) {
            setSummaryData(response);
            fetchAccountTransactionsSummaryReportData();
          } else {
            validateAndShowError(response.Status);
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const fetchAccountTransactionsSummaryReportData = () => {
    try {
      const report = getUserReportByAPI(REPORT_ACC2T);
      const payload = {
        JWTid: appContext.userDetails.JWTid,
        reportId: report ? report.ReportId : SUMMARY_ACC2T,
        appId: appContext.selectedUserApp.AppId,
        AccountId: id,
        startDate: getDateBySubtractMonth(3),
        endDate: getCurrentDateYYYYMMDD(),
        PageNumber: 1,
        PageSize: DEFAULT_PAGE_SIZE,
        timezoneOffset: DEFAULT_TIMEZONE_OFFSET,
      };
      setShowLoader(true);
      fetchAccountTransactionsSummaryReport(payload)
        .then((response) => {
          if (response.Status === 0) {
            setSummaryTransactionsData(response);
          } else {
            validateAndShowError(response.Status);
          }
        })
        .catch((err) => {
          showCatchedExceptionOnToast(toast, 2, err);
        })
        .finally(() => {
          setShowLoader(false);
        });
    } catch (err) {
      showCatchedExceptionOnToast(toast, 2, err);
    }
  };

  const gotoLookupTab = () => {
    setSelectedTab(1);
    setShiftColumn(true);
  };

  const onClickBack = () => {
    navigate(-1);
  };

  const refreshData = () => {
    if (type === "device") {
      fetchDeviceSummaryReportData();
    } else if (type === "account") {
      fetchAccountSummaryReportData();
    }
  };

  if (showLoader) {
    return <Loader />;
  }

  return (
    <Box>
      {summaryData && (
        <>
          {summaryData &&
            summaryData.Data.Results.Acc1Info?.Status === "blocked" &&
            type === "account" && (
              <ErrorAlert
                info={
                  summaryData.Data.Results.Acc1Info?.BlockedDate
                    ? "Account blocked " +
                      formatDateBasedOnLocale(
                        summaryData.Data.Results.Acc1Info?.BlockedDate
                      )
                    : "Account blocked"
                }
              />
            )}
          {summaryData &&
            summaryData.Data.Results.Dev1Info?.Status === "blocked" &&
            type === "device" && (
              <ErrorAlert
                info={
                  summaryData.Data.Results.Dev1Info?.BlockedDate
                    ? "Device blocked " +
                      formatDateBasedOnLocale(
                        summaryData.Data.Results.Dev1Info?.BlockedDate
                      )
                    : "Device blocked"
                }
              />
            )}

          <SummaryTabHeader
            onTabSelect={onTabSelect}
            count={
              summaryTransactionsData
                ? summaryTransactionsData.Data.TotalRecords
                : 0
            }
            currentTab={selectedTab}
          />
          <div style={{ height: ".5rem" }} />
          <div style={{ display: selectedTab === 0 ? "block" : "none" }}>
            <SummaryPageContainer
              summary={summaryData}
              summaryType={type}
              summaryId={id}
              lookupTabHandler={gotoLookupTab}
              refreshData={refreshData}
              theme={theme}
            />
          </div>

          <div style={{ display: selectedTab === 1 ? "block" : "none" }}>
            <TransactionsPageContainer
              transactions={summaryTransactionsData}
              summaryType={type}
              summaryId={id}
              tabIndex={selectedTab}
              shiftColumn={shiftColumn}
            />
          </div>

          <div style={{ display: selectedTab === 2 ? "block" : "none" }}>
            <SummaryMapContainer
              data={
                summaryTransactionsData
                  ? summaryTransactionsData.Data.Results
                  : null
              }
              summaryType={type}
              summaryId={id}
              tabIndex={selectedTab}
            />
          </div>
        </>
      )}
      {!summaryData && (
        <>
          <IconButton onClick={onClickBack} sx={{ marginBottom: ".5rem" }}>
            <ChevronLeftIcon style={{ color: "black" }} />
          </IconButton>
          <GenericError data={getErrorByType()} />
        </>
      )}
      <ToastContainer position="top-center" autoClose={3000} />
    </Box>
  );
};

export default Overview;
