import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { GridCloseIcon } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { SEARCH_CRITERIA_LIST } from "../../constants/Constants";
import { AppContext } from "../../context/AppContext";

export default function ACTDeleteSavedReportDialog(props) {
  const {
    openDialog,
    closeHandler,
    selectedSearchItem,
    deleteSavedReportHandler,
  } = props;

  const appContext = useContext(AppContext)
  const [title, setTitle] = useState("");
  const [deletedSearch, setDeletedSearch] = useState("");
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedSearchItem) {
      setTitle(selectedSearchItem.name);
    }
  }, [selectedSearchItem]);

  useEffect(() => {
    if (selectedSearchItem) {
      setDeletedSearch(selectedSearchItem);
    }
  }, [selectedSearchItem]);

  const handleClose = () => {
    closeHandler(false);
    resetDeleteButton();
  };

  const onDeleteReport = () => {
    const searchCriteriaList = localStorage.getItem(SEARCH_CRITERIA_LIST);
    if (searchCriteriaList) {
      const list = JSON.parse(searchCriteriaList);

      const searchMenuList = list.filter((item) => {
        if (item.title !== selectedSearchItem.title) {
          return item;
        }
      });
      localStorage.setItem(
        SEARCH_CRITERIA_LIST,
        JSON.stringify(searchMenuList)
      );

      resetDeleteButton();
      appContext.setRefreshSavedSearchSideMenu(Math.random());
      navigate("/last-seven-days");

    }
  };

  const deleteReport = () => {
    if (deleteSavedReportHandler) {
      deleteSavedReportHandler(selectedSearchItem, deletedSearch);
    }
    closeHandler(false);
    resetDeleteButton();
    navigate("/last-seven-days");
  };

  const resetDeleteButton = () => {
    setTimeout(function resetDelete() {
      setIsDeleteOpen(false);
    }, 500);
  };

  const theme = useTheme();


  return (
    <React.Fragment>
      <Dialog
        sx={{
          "& .MuiDialogContent-root": {
            padding: "1.25rem 2.5rem",
          },
          "& .MuiPaper-root": {
            width: "90vw",
            maxWidth: "23.438rem",
          },
        }}
        open={openDialog}
        onClose={handleClose}
      >
        <GridCloseIcon
          onClick={handleClose}
          sx={{
            height: "1.2rem",
            position: "absolute",
            right: "1rem",
            top: "1rem",
            cursor: "pointer",
          }}
        />
        <DialogTitle
          sx={{
            padding: "2.5rem 0rem 0rem 2.5rem",
            fontSize: "1rem",
            fontWeight: "700",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "21.5rem",
          }}
        >
          {"Delete Report: "}
          {selectedSearchItem.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              fontSize: "0.8rem",
              fontWeight: "500",
              padding: "0 0 0 0",
            }}
          >
            This cannot be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            padding: "0rem 1.5rem 2.25rem 1.5rem",
          }}
        >
          <Button
            sx={{
              fontSize: "0.7rem",
              fontWeight: "600",
            }}
            onClick={onDeleteReport}
            autoFocus
          >
            delete
          </Button>
          <Button
            sx={{
              fontSize: "0.7rem",
              fontWeight: "600",
            }}
            onClick={handleClose}
          >
            cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
