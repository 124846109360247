import { Box, IconButton, Typography } from "@mui/material";
import { getReasonsNameByCode } from "../../utils/CommonUtils";
import { formatDateBasedOnLocale } from "../../utils/DateUtils";
import { CopyAllRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import ACTTransactionsMultiple from "./ACTTransactionsMultiple";
import { Link as RouterLink } from "react-router-dom";

const ACTTransaction = ({
  selectedGeoRefId,
  points,
  failedReasons,
  onCopyData,
  onViewSimilarLocations,
}) => {
  const result = points.find(
    (item) => item.GeoReferenceId === selectedGeoRefId
  );
  const latLngGroup = points.filter(
    (item) =>
      item.Latitude === result.Latitude && item.Longitude === result.Longitude
  );
  const theme = useTheme();
  const customtextColorForDarkmode = (theme) => ({
    color: theme.palette.mode === "dark" && "#ffffff",
  });

  return (
    <>
      {latLngGroup.length > 1 && (
        <>
          <ACTTransactionsMultiple
            selectedGeoRefId={selectedGeoRefId}
            points={points}
            failedReasons={failedReasons}
            onCopyData={onCopyData}
            latLngGroup={latLngGroup}
          />
        </>
      )}
      {latLngGroup.length === 1 && (
        <Box
          sx={{
            width: "26rem",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
            gap: "0.15rem",
            paddingLeft: "0.75rem",
          }}
          style={customtextColorForDarkmode(theme)}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "1.2rem",
            }}
          >
            <Typography
              sx={{
                marginRight: "0.2rem",
                fontSize: "0.8125rem",
                lineHeight: "1",
                width: "370px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              style={customtextColorForDarkmode(theme)}
            >
              <strong>Date & Time:</strong>{" "}
              <>{formatDateBasedOnLocale(result.Timestamp)}</>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "1.2rem",
            }}
          >
            <Typography
              sx={{
                marginRight: "0.2rem",
                fontSize: "0.8125rem",
                lineHeight: "1",
                width: "370px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              style={customtextColorForDarkmode(theme)}
            >
              <strong>Device Type: </strong>
              {result.DeviceType}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "1.2rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "0.8125rem",
                lineHeight: "1",
                width: "370px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              style={customtextColorForDarkmode(theme)}
            >
              <strong>Device ID: </strong>
              <Link
                to={"/summary/device/" + result.DeviceId}
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: "underline",
                }}
              >
                {result.DeviceId}
              </Link>
            </Typography>
            <div>
              <IconButton
                aria-label="copy"
                size="small"
                onClick={() => onCopyData(result.DeviceId)}
              >
                <CopyAllRounded
                  sx={{
                    fontSize: "1.3rem",
                  }}
                  style={customtextColorForDarkmode(theme)}
                />
              </IconButton>
            </div>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "1.2rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "0.8125rem",
                lineHeight: "1",
                width: "370px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              style={customtextColorForDarkmode(theme)}
            >
              <strong>Account ID: </strong>
              <Link
                to={"/summary/account/" + result.AccountId}
                style={{
                  color: theme.palette.text.primary,
                  textDecoration: "underline",
                }}
              >
                {result.AccountId}
              </Link>
            </Typography>
            <div>
              <IconButton
                aria-label="copy"
                size="small"
                onClick={() => onCopyData(result.AccountId)}
              >
                <CopyAllRounded
                  sx={{
                    fontSize: "1.3rem",
                  }}
                  style={customtextColorForDarkmode(theme)}
                />
              </IconButton>
            </div>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "1.2rem",
            }}
          >
            <Typography
              sx={{
                fontSize: "0.8125rem",
                lineHeight: "1",
                width: "370px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              style={customtextColorForDarkmode(theme)}
            >
              <strong>Lat & Long:</strong> {result.Latitude}, {result.Longitude}
            </Typography>
            <div>
              <IconButton
                aria-label="copy"
                size="small"
                onClick={() =>
                  onCopyData(result.Latitude + "," + result.Longitude)
                }
              >
                <CopyAllRounded
                  sx={{
                    fontSize: "1.3rem",
                  }}
                  style={customtextColorForDarkmode(theme)}
                />
              </IconButton>
            </div>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "1.2rem",
            }}
          >
            <Typography
              sx={{
                marginRight: "0.2rem",
                fontSize: "0.8125rem",
                lineHeight: "1",
                width: "370px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              style={customtextColorForDarkmode(theme)}
            >
              <strong>Status: </strong>{" "}
              {getReasonsNameByCode(result.ReasonForFailure[0], failedReasons)}{" "}
            </Typography>
            <></>

            <br></br>
          </Box>

          <br></br>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "1.2rem",
              marginBottom: "0.25rem",
            }}
          >
            <Typography
              component={RouterLink}
              to={"/summary/device/" + result.DeviceId}
              state={{ type: "device", id: result.DeviceId }}
              sx={{
                fontSize: "13px",
                color: "#1e86f0",
                marginBottom: "0.5rem",
                textDecoration: "none",
                "&:hover": {
                  opacity: "0.8",
                },
                "&:visited": {
                  color: "#1e86f0",
                },
              }}
            >
              {"view all device activity"}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ACTTransaction;
