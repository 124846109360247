import { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material/styles";
import ACTTransactionsMultipleAllList from "./ACTTransactionsMultipleAllList";
import CloseIcon from "@mui/icons-material/Close";
import { Height } from "@mui/icons-material";
import { colors } from "@mui/material";

export default function ACTTransactionsMultipleSidebar({
  selectedGeoRefId,
  points,
  failedReasons,
  onCopyData,
}) {
  const result = points.find(
    (item) => item.GeoReferenceId === selectedGeoRefId
  );
  const latLngGroup = points.filter(
    (item) =>
      item.Latitude === result.Latitude && item.Longitude === result.Longitude
  );

  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const theme = useTheme();

  const DrawerContent = (
    <Box
      sx={{
        width: 400,
        padding: "0.5rem 0rem 0.5rem 0rem",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        gap: "0.25rem",
        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
        "&:nth-of-type(odd)": {
          backgroundColor: "none",
        },
        "&:nth-of-type(even)": {
          backgroundColor: theme.palette.mode === "light" && "#ececec",
          borderTop: "0.1rem solid #ececec",
          borderBottom: "0.1rem solid #ececec",
        },
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <CloseIcon
        onClick={toggleDrawer(false)}
        sx={{
          fontSize: "1.5rem",
          margin: "0.85rem 0 0.85rem 0.7rem",
          cursor: "pointer",
          opacity: "0.7",
          "&:hover": {
            opacity: "1",
          },
        }}
      />
      <ACTTransactionsMultipleAllList
        failedReasons={failedReasons}
        onCopyData={onCopyData}
        moreItems={latLngGroup}
        latLngGroup={latLngGroup}
      />
    </Box>
  );
  return (
    <div>
      <button
        style={{
          fontSize: "0.8rem",
          color: "#1e86f0",
          padding: "2rem 1rem 2rem",
          fontWeight: "400",
        }}
        onClick={toggleDrawer(true)}
      >
        {!open ? "view all checks" : "hide checks"}
      </button>
      <Drawer
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
        transitionDuration={300}
        ModalProps={{
          BackdropProps: { invisible: true },
        }}
        disableScrollLock={true}
        elevation={0}
      >
        {DrawerContent}
      </Drawer>
    </div>
  );
}
