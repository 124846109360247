export const calculateZoomLevel = (bounds, mapDimensions) => {
    const WORLD_DIM = { height: 256, width: 256 };
    const ZOOM_MAX = 21;
  
    const latRad = (lat) => {
      const sin = Math.sin((lat * Math.PI) / 180);
      return Math.log((1 + sin) / (1 - sin)) / 2;
    };
  
    const zoom = (mapPx, worldPx, fraction) =>
      Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  
    const ne = { lat: bounds.north, lng: bounds.east };
    const sw = { lat: bounds.south, lng: bounds.west };
  
    const latFraction = (latRad(ne.lat) - latRad(sw.lat)) / Math.PI;
    const lngDiff = ne.lng - sw.lng;
    const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;
  
    const latZoom = zoom(mapDimensions.height, WORLD_DIM.height, latFraction);
    const lngZoom = zoom(mapDimensions.width, WORLD_DIM.width, lngFraction);
  
    return Math.min(latZoom, lngZoom, ZOOM_MAX);
  };
  